import styled from "styled-components";

const getBackgroundColor = (sTheme: string) => {
  if (sTheme === "adene") {
    return "#EE0000";
  }

  if (sTheme === "nomad") {
    return "#FFFFFF";
  }

  return "#EE0000";
};

const getColor = (sTheme: string) => {
  if (sTheme === "adene") {
    return "#FFFFFF";
  }

  if (sTheme === "nomad") {
    return "#EE0000";
  }

  return "#FFFFFF";
};

export default styled.div<{ stheme: string }>`
  display: inline-block;
  background: ${({ stheme }) => getBackgroundColor(stheme)};
  margin-top: ${(props) =>
    props.stheme !== "adene" && props.stheme !== "nomad" ? "15px" : "30px"};
  padding: 5px 10px;
  text-align: center;
  width: 100%;
  color: ${({ stheme }) => getColor(stheme)};
`;
