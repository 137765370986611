import AppConfig from './interfaces/appConfig';

const config: AppConfig = {
  jwtPUBLIC: process.env.REACT_APP_JWT_PUBLIC || '',
  serverURL: process.env.REACT_APP_SERVER_URL || '',
  theme: process.env.REACT_APP_THEME || 'ads',
  version: process.env.REACT_APP_VERSION || ''
};

export default config;
