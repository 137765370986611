import { resetAuthState } from "../../utils";
import H1 from "../core/H1";
import View from "../View";

export default function LogoutView() {
  resetAuthState();

  return (
    <View title="Déconnexion">
      <H1>Déconnexion réussie !</H1>
    </View>
  );
}
