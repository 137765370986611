export default class AuthException extends Error {
  readonly message: string;
  readonly statusCode: number;

  constructor(statusCode: number) {
    super();
    this.statusCode = statusCode;

    if (statusCode === 401)
      this.message = 'Identifiants incorrects';
    else if (statusCode < 500)
      this.message = 'Connexion impossible';
    else
      this.message = 'Une erreur est survenue';
  }
}
