import type { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface ViewProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

const Div = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
`;

export default function View({ children, title, ...props }: ViewProps) {
  document.title = title;
  return <Div {...props}>{children}</Div>;
};
