import styled from 'styled-components';

export default styled.input`
  border: 1px solid ${props => props.theme.border.light};
  border-radius: 5px;
  padding: 10px;
  outline: none;
  width: 100%;

  &::placeholder {
    color: ${props => props.theme.colors.neutral.hover};
  }
`;
