import styled from "styled-components";

export default styled.img`
  display: block;
  height: auto;
  max-height: 70px;
  width: auto;
  max-width: 100%;
  margin: 0 auto 20px;
`;
