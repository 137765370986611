import { createGlobalStyle } from 'styled-components';

import EMprintRegularEOT from '../fonts/EMprint/EMprint Regular/EMprint Regular.eot';
import EMprintRegularWOFF2 from '../fonts/EMprint/EMprint Regular/EMprint Regular.woff2';
import EMprintRegularWOFF from '../fonts/EMprint/EMprint Regular/EMprint Regular.woff';
import EMprintRegularTTF from '../fonts/EMprint/EMprint Regular/EMprint Regular.ttf';
import EMprintRegularSVG from '../fonts/EMprint/EMprint Regular/EMprint Regular.svg';

import EMprintLightEOT from '../fonts/EMprint/EMprint Light/EMprint Light.eot';
import EMprintLightWOFF2 from '../fonts/EMprint/EMprint Light/EMprint Light.woff2';
import EMprintLightWOFF from '../fonts/EMprint/EMprint Light/EMprint Light.woff';
import EMprintLightTTF from '../fonts/EMprint/EMprint Light/EMprint Light.ttf';
import EMprintLightSVG from '../fonts/EMprint/EMprint Light/EMprint Light.svg';

import EMprintBoldEOT from '../fonts/EMprint/EMprint Bold/EMprint Bold.eot';
import EMprintBoldWOFF2 from '../fonts/EMprint/EMprint Bold/EMprint Bold.woff2';
import EMprintBoldWOFF from '../fonts/EMprint/EMprint Bold/EMprint Bold.woff';
import EMprintBoldTTF from '../fonts/EMprint/EMprint Bold/EMprint Bold.ttf';
import EMprintBoldSVG from '../fonts/EMprint/EMprint Bold/EMprint Bold.svg';

import FuturaLTBookOTF from '../fonts/Futura/Futura-LT-Book.otf';
import FuturaLTBookTTF from '../fonts/Futura/Futura-LT-Book.ttf';

import UniversCondensedOTF from '../fonts/Univers/Univers-Condensed.otf';
import UniversCondensedTTF from '../fonts/Univers/Univers-Condensed.ttf';
import UniversCondensedBoldOTF from '../fonts/Univers/Univers-Condensed-Bold.otf';
import UniversCondensedBoldTTF from '../fonts/Univers/Univers-Condensed-Bold.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {font-family: 'FuturaLT';
    src: url('${FuturaLTBookOTF}');
    url('${FuturaLTBookTTF}') format('truetype');
  }

  @font-face {font-family: 'UniversCondensed';
    src: url(${UniversCondensedOTF});
    url('${UniversCondensedTTF}') format('truetype');
  }

  @font-face {font-family: 'UniversCondensedBold';
    src: url(${UniversCondensedBoldOTF});
    url('${UniversCondensedBoldTTF}') format('truetype');
  }

  @font-face {font-family: 'EMprint Regular';
    src: url('${EMprintRegularEOT}');
    src: url('${EMprintRegularEOT}?#iefix') format('embedded-opentype'),
    url('${EMprintRegularWOFF2}') format('woff2'),
    url('${EMprintRegularWOFF}') format('woff'),
    url('${EMprintRegularTTF}') format('truetype'),
    url('${EMprintRegularSVG}#EMprint Light') format('svg');
  }

  @font-face {font-family: 'EMprint Light';
    src: url('${EMprintLightEOT}');
    src: url('${EMprintLightEOT}?#iefix') format('embedded-opentype'),
    url('${EMprintLightWOFF2}') format('woff2'),
    url('${EMprintLightWOFF}') format('woff'),
    url('${EMprintLightTTF}') format('truetype'),
    url('${EMprintLightSVG}#EMprint Light') format('svg');
  }

  @font-face {font-family: 'EMprint Bold';
    src: url('${EMprintBoldEOT}');
    src: url('${EMprintBoldEOT}?#iefix') format('embedded-opentype'),
    url('${EMprintBoldWOFF2}') format('woff2'),
    url('${EMprintBoldWOFF}') format('woff'),
    url('${EMprintBoldTTF}') format('truetype'),
    url('${EMprintBoldSVG}#EMprint Light') format('svg');
  }

  * {
    box-sizing: border-box;
  }

  html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    background: ${(props) => props.theme.background.default};
  }



  body {
    margin: 0;
    padding: 0;
    min-width: 100%;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;

    font: 400 1.23077em/1.45em 'EMprint Regular', sans-serif;
    font-size: 1.4rem;
    line-height: 1.2857142857142857142857142857143em;
    text-rendering: optimizeLegibility !important;
    font-feature-settings: 'liga' 1, 'onum' 1, 'calt' 0 !important;

    color: ${(props) => props.theme.colors.textBlack} !important;
    background: ${(props) => props.theme.background.default} !important;
    overscroll-behavior: none;
  }

  .grid a {
    color: ${(props) => props.theme.colors.textBlack} !important;
    transition: color 0.25s ease-in-out;
  }
  .grid a:hover {
    color: ${(props) => props.theme.colors.greenLight} !important;
  }



  label, strong {
    font-family:'EMprint Bold' !important;
  }

  input, optgroup, select, textarea,
  .ui.input>input,
  .ui.form input:not([type]),
  .ui.form input[type=date],
  .ui.form input[type=datetime-local],
  .ui.form input[type=email],
  .ui.form input[type=file],
  .ui.form input[type=number],
  .ui.form input[type=password],
  .ui.form input[type=search],
  .ui.form input[type=tel],
  .ui.form input[type=text],
  .ui.form input[type=time],
  .ui.form input[type=url]
   {
    font-family:'EMprint Regular' !important;
  }

  button {
    font-family:'EMprint Light' !important;
  }

  .flex-col {
    display: flex !important;
    flex-direction: column;
  }



  @media (min-width: 1200px) {
    body {
      font-size: 1.6rem;
    }
  }
`;

export default GlobalStyle;
