import type AuthResponse from "./interfaces/authResponse";

// const tokenItemName = "idToken";

export function getStoredToken() {
  return getCookie("Authorization");
  // return localStorage.getItem(tokenItemName);
}

export function resetAuthState() {
  document.cookie =
    "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // localStorage.removeItem(tokenItemName);
}

export function saveAuthState({ cookie, token }: AuthResponse) {
  document.cookie = cookie;
  // localStorage.setItem(tokenItemName, token);
}

export function sendToken(token: string, clientUrl: string) {
  try {
    window.parent.postMessage({ action: "token", token }, "*");
  } catch (error) {
    throw new Error("Une erreur est survenue");
  }
}

export function getCookie(name: string) {
  const match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + name + "=([^;]*)")
  );
  return match ? match[1] : null;
}
