import type { ButtonHTMLAttributes } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

interface SubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const Button = styled.button`
  background: ${props => props.theme.colors.primary.main};
  border: 0;
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  height: 40px;
  text-transform: uppercase;
  transition: background ease-in-out 0.25s;
  width: 100%;
`;

export default function SubmitButton({ children, loading, ...props }: SubmitButtonProps) {
  return <Button {...props} type='submit'>
    {loading ? <Icon loading name='spinner' /> : children}
  </Button>;
}
