import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./ui/assets/css/global-css";

import LoginView from "./components/views/login";
import LogoutView from "./components/views/Logout";
import NotFoundView from "./components/views/NotFound";

const theme = require(`./ui/theme.ts`);

export default function App() {
  return (
    <ThemeProvider theme={theme.default}>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginView} />
          <Route exact path="/logout" component={LogoutView} />
          <Route component={NotFoundView} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
