import { resetAuthState } from '../../utils';
import H1 from '../core/H1';
import View from '../View';

export default function NotFoundView() {
  resetAuthState();

  return <View title='Page introuvable'>
    <H1>Adresse erronée</H1>
  </View>;
};
